// src/PrintContext.tsx
import { createContext } from "react";
import { jsx } from "react/jsx-runtime";
var PrintContext = createContext({});
var PrintProvider = ({ children, paperWidthSize, printMode, appMode = false, fontSize, paperSize }) => {
  return /* @__PURE__ */ jsx(
    PrintContext.Provider,
    {
      value: {
        paperWidthSize,
        printMode,
        appMode,
        fontSize,
        paperSize
      },
      children
    }
  );
};

// src/components/Print/PrintBreakline.tsx
import { useContext } from "react";
import { jsx as jsx2 } from "react/jsx-runtime";
var PrintBreakline = () => {
  const { printMode, appMode } = useContext(PrintContext);
  switch (printMode) {
    case "formated":
    case "pdf":
      return /* @__PURE__ */ jsx2("br", {});
    case "text-only":
      return /* @__PURE__ */ jsx2("pre", { children: appMode ? "\n" : " " });
  }
};

// src/components/Print/PrintLineSeparator.tsx
import { useContext as useContext2 } from "react";
import { View } from "@react-pdf/renderer";
import { jsx as jsx3 } from "react/jsx-runtime";
var PrintLineSeparator = () => {
  const { paperWidthSize, printMode } = useContext2(PrintContext);
  const separator = new Array(paperWidthSize).fill("_");
  return /* @__PURE__ */ jsx3("pre", { className: `lineseparator ${printMode}`, children: printMode !== "pdf" ? separator.join("") + "\n" : /* @__PURE__ */ jsx3(View, { style: { border: "1px dashed black", height: 0 } }) });
};

// src/components/Print/PrintRoot.tsx
import { forwardRef } from "react";
import { jsx as jsx4 } from "react/jsx-runtime";
var PrintRoot = forwardRef(function PrintRoot2({ children, paperWidthSize, printMode, fontSize, paperSize }, ref) {
  return /* @__PURE__ */ jsx4(PrintProvider, { paperWidthSize, printMode, fontSize, paperSize, children: /* @__PURE__ */ jsx4("pre", { className: "print-root", ref, children }) });
});

// src/components/Print/PrintRow.tsx
import { StyleSheet, Text, View as View2 } from "@react-pdf/renderer";
import { useContext as useContext3 } from "react";
import { jsx as jsx5, jsxs } from "react/jsx-runtime";
var PrintRow = ({ left = "", center = "", right = "", leftClass = "", className, children_controls, ...rest }) => {
  const { printMode, paperWidthSize, fontSize: printFontSize, paperSize } = useContext3(PrintContext);
  let content;
  const splitString = (text2, limit) => {
    const regex = new RegExp(`.{1,${Math.max(1, limit)}}`, "gmu");
    return Array.from(text2?.match(regex) ?? []);
  };
  let text = "";
  const rowsObject = {
    left,
    center,
    right
  };
  Object.entries(rowsObject).forEach(([key, value], index, arr) => {
    const lengths = {};
    let rowTextLength = 0;
    if (index === 0) {
      arr.forEach(([k, str]) => {
        lengths[k] = { length: str?.length };
        rowTextLength += str?.length;
      });
    }
    let result = value;
    if (result?.length >= paperWidthSize) {
      rowsObject[key] = splitString(
        result,
        paperWidthSize - arr.reduce((total, [ak, as]) => {
          if (ak !== key) {
            total += as.length;
          }
          return total;
        }, 1)
      );
    } else if (rowTextLength > paperWidthSize) {
      rowsObject[key] = splitString(result, lengths[key].length - (rowTextLength + 1 - paperWidthSize) || 1);
    } else {
      rowsObject[key] = splitString(result, result?.length + 1 || 1);
    }
  });
  let rowsCount = 0;
  let newRow = {};
  const rowsLength = Math.max(...Object.values(rowsObject).map((a) => a.length));
  Object.entries(rowsObject).forEach(([key, value]) => {
    while (value.length < rowsLength) {
      if (Array.isArray(value)) {
        value.push("");
      }
    }
    rowsObject[key] = value;
    return rowsObject;
  });
  while (rowsCount < rowsLength) {
    let rowCharLength = 0;
    Object.entries(rowsObject).forEach(([key]) => {
      if (rowsObject[key] && rowsObject[key][rowsCount]) {
        newRow[key] = rowsObject[key][rowsCount];
        rowCharLength += rowsObject[key][rowsCount].length;
      }
    });
    const blankSpaces = paperWidthSize - rowCharLength;
    if (newRow.center?.length || children_controls === "mid") {
      newRow.center = `${" ".repeat(blankSpaces / 2)}${newRow.center}${" ".repeat(blankSpaces / 2)}`;
    } else {
      newRow.center = " ".repeat(Math.max(blankSpaces, 0));
    }
    const newText = `${newRow.left}${newRow.center}${newRow.right}`;
    text += newText;
    rowsCount++;
  }
  text = splitString(text, paperWidthSize).join("\n");
  text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  switch (printMode) {
    case "text-only":
      content = /* @__PURE__ */ jsx5("pre", { children: text });
      break;
    case "formated":
      content = /* @__PURE__ */ jsxs("div", { style: { fontWeight: "bolder" }, children: [
        /* @__PURE__ */ jsx5("p", { className: leftClass, children: children_controls === "start" ? rest.children : left.normalize("NFD").replace(/[\u0300-\u036f]/g, "") }),
        /* @__PURE__ */ jsx5("p", { children: children_controls === "mid" ? rest.children : center.normalize("NFD").replace(/[\u0300-\u036f]/g, "") }),
        /* @__PURE__ */ jsx5("p", { children: children_controls === "end" ? rest.children : right?.normalize("NFD").replace(/[\u0300-\u036f]/g, "") })
      ] });
      break;
    case "pdf":
      let styles = {};
      switch (leftClass) {
        case "complement-space":
          styles = StyleSheet.create({
            [leftClass]: {
              marginLeft: `${paperWidthSize === 32 ? 4 : 13}mm`
            }
          });
          break;
        case "item-space":
          styles = StyleSheet.create({
            [leftClass]: {
              marginLeft: `${paperWidthSize === 32 ? 8 : 20}mm`
            }
          });
          break;
        default:
          break;
      }
      const fontSize = className?.includes("print-title") ? "20pt" : printFontSize === 7 ? "14pt" : "18pt";
      const viewWidth = paperWidthSize === 32 ? 72 : 109;
      content = /* @__PURE__ */ jsxs(
        View2,
        {
          style: [
            {
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              width: `${viewWidth}mm`,
              fontSize,
              marginBottom: className?.includes("print-title") ? "24pt" : 0
            },
            styles[leftClass]
          ],
          children: [
            /* @__PURE__ */ jsx5(Text, { wrap: true, style: { maxWidth: `${viewWidth - (printFontSize === 7 ? 18 : 15)}mm` }, children: left.normalize("NFD").replace(/[\u0300-\u036f]/g, "") }),
            /* @__PURE__ */ jsx5(Text, { wrap: true, style: { textAlign: "center", flex: 1 }, children: center.normalize("NFD").replace(/[\u0300-\u036f]/g, "") }),
            /* @__PURE__ */ jsx5(Text, { style: { textAlign: "left", marginLeft: "auto" }, children: right.normalize("NFD").replace(/[\u0300-\u036f]/g, "") })
          ]
        }
      );
      break;
  }
  return /* @__PURE__ */ jsx5("pre", { className: `print-row ${printMode} ${`layout-${paperSize}mm`} ${className ?? ""}`, ...rest, children: content });
};

// src/components/Print/index.tsx
var Print = {
  Root: PrintRoot,
  Breakline: PrintBreakline,
  LineSeparator: PrintLineSeparator,
  Row: PrintRow
};

// src/components/NotePrint/index.tsx
import { DateTime } from "luxon";
import { Fragment, forwardRef as forwardRef2 } from "react";
import { QRCodeCanvas } from "qrcode.react";

// src/utils/currency.ts
var currency = ({
  value = 0,
  symbol = false,
  withoutSymbol = false,
  language = "pt-BR",
  currency: currency2 = "BRL"
}) => {
  if (!value || isNaN(value)) {
    value = 0;
  }
  value = value && parseFloat(value.toString());
  if (symbol) {
    return 0 .toLocaleString(language, {
      style: "currency",
      currency: currency2
    }).replace(/\d+(,|\.)\d+/, "");
  }
  if (withoutSymbol) {
    return new Intl.NumberFormat(language, {
      style: "currency",
      currency: currency2
    }).format(value).replace(/\D+/, "");
  }
  return new Intl.NumberFormat(language, {
    style: "currency",
    currency: currency2
  }).format(value);
};

// src/components/NotePrint/index.tsx
import { Fragment as Fragment2, jsx as jsx6, jsxs as jsxs2 } from "react/jsx-runtime";
var NotePrint = forwardRef2(function NotePrint2({
  profile,
  cart,
  printType,
  report = false,
  table,
  detailedTable,
  command,
  pdf,
  electron,
  paperSize,
  motoboys,
  textPackage
}, ref) {
  if (!cart) {
    return null;
  }
  if (!printType) {
    table = cart?.command?.opened?.table;
  }
  const getItens = () => {
    let items = [];
    switch (printType) {
      case "table":
        if (table && table.opened) {
          items = table.opened.commands.flatMap(
            (command2) => command2.carts.filter((c) => c.status !== "canceled").reduce((carts, cart2) => {
              if (!carts.some((c) => c.id === cart2.id)) {
                carts.push(cart2);
              }
              return carts;
            }, []).flatMap((cart2) => {
              return cart2.groupItens(profile.options.print.groupItems);
            })
          );
        }
        break;
      case "command":
        if (command) {
          items = command.carts.filter((c) => c.status !== "canceled").flatMap((cart2) => {
            return cart2.groupItens(profile.options.print.groupItems);
          });
        }
        break;
      default:
        items = cart.groupItens(profile.options.print.groupItems);
        break;
    }
    return items;
  };
  const getCupomValue = () => {
    let cupomDisplayValue;
    let value = 0;
    if (cart.cupom) {
      switch (cart.cupom.type) {
        case "value":
          value = Number(cart.cupom.value);
          cupomDisplayValue = currency({ value, withoutSymbol: true });
          break;
        case "percent":
          value = cart.getTotalValue("subtotal") / 100 * Number(cart.cupom.value || 0);
          cupomDisplayValue = currency({ value, withoutSymbol: true });
          break;
        case "freight":
          cupomDisplayValue = "Frete Gr\xE1tis";
          break;
        default:
          break;
      }
    }
    return { cupomDisplayValue, value };
  };
  const addonTypeLabel = () => {
    let type = "";
    if (getFormsPaymentToPrint()[0]?.addon.status) {
      switch (getFormsPaymentToPrint()[0]?.addon.type) {
        case "fee":
          type = `Acr\xE9scimo ${getFormsPaymentToPrint()[0]?.label}`;
          break;
        case "discount":
          type = `Desconto ${getFormsPaymentToPrint()[0]?.label}`;
          break;
      }
    }
    return type;
  };
  const getFeesToPrint = () => {
    let fees = [];
    switch (printType) {
      case "table":
        fees = table?.opened?.getUpdatedFees(false, true) ?? [];
        break;
      default:
        fees = cart?.command?.fees ?? [];
        break;
    }
    return fees;
  };
  const getFormsPaymentToPrint = (noCashback = true) => {
    let formsPayment = [];
    switch (printType) {
      case "table":
        formsPayment = cart?.command?.opened?.formsPayment ?? [];
        break;
      case "command":
        formsPayment = cart?.command?.formsPayment ?? [];
        break;
      default:
        formsPayment = (cart.statusPayment === "offline" ? cart?.formsPayment : cart?.formsPayment.filter(
          (f) => f.paid || f.payment === "cashback"
        )) ?? [];
        if (noCashback) {
          formsPayment = formsPayment.filter(
            (formPayment) => formPayment.payment !== "cashback"
          );
        }
        break;
    }
    return formsPayment;
  };
  const getPaidValue = () => {
    let paid = 0;
    switch (printType) {
      case "command":
        paid = cart.command?.getTotalValue("paid") || 0;
        break;
      case "table":
        paid = table?.opened?.getTotalValue("paid") || 0;
        break;
      default:
        paid = cart.formsPayment.reduce(
          (total, formPayment) => total += formPayment.value,
          0
        ) || 0;
        break;
    }
    return currency({ value: paid, withoutSymbol: true });
  };
  const getLackValue = () => {
    let lack = 0;
    switch (printType) {
      case "command":
        lack = cart.command?.getTotalValue("lack") || 0;
        break;
      case "table":
        lack = table?.opened?.getTotalValue("lack") || 0;
        break;
      default:
        lack = (cart?.getTotalValue("lack") || 0) - getCupomValue().value;
        break;
    }
    return currency({ value: lack, withoutSymbol: true });
  };
  const complementLayout = (complement) => {
    return /* @__PURE__ */ jsxs2(Fragment2, { children: [
      /* @__PURE__ */ jsx6(Print.Breakline, {}),
      /* @__PURE__ */ jsx6(
        Print.Row,
        {
          leftClass: "complement-space",
          left: `${" ".repeat(3)}${complement.name}`
        },
        complement.id
      ),
      complement.itens?.map((complementItem, index) => {
        const complementItemTotal = complementItem.value > 0 ? `(${currency({ value: complementItem.value * (complementItem.quantity || 1), withoutSymbol: true })})` : "";
        return /* @__PURE__ */ jsx6(
          Print.Row,
          {
            leftClass: "item-space",
            left: `${" ".repeat(5)}${complementItem.quantity}X | ${complementItem.name}`,
            center: "",
            right: `${complementItemTotal} `
          },
          `${complementItem.code}-${index}`
        );
      })
    ] });
  };
  const implementationLayout = (implementation) => {
    if (!implementation) {
      return /* @__PURE__ */ jsx6(Fragment2, {});
    }
    return /* @__PURE__ */ jsx6(
      Print.Row,
      {
        leftClass: "complement-space",
        left: `${" ".repeat(3)}${implementation.name}`,
        right: `${currency({ value: implementation.value, withoutSymbol: true })}`
      },
      implementation.code
    );
  };
  const getItemsToPrint = (items) => {
    return items?.map((cartItem) => {
      const cartItemTotal = cartItem.details.value > 0 ? `${currency({ value: cartItem.getTotal(cartItem.type === "default"), withoutSymbol: true })}` : "";
      const cartItemTotalWithOutSymbol = cartItem.details.value > 0 ? `(${currency({
        value: cartItem.type === "pizza" ? cartItem.details.value - cartItem.details.complements.reduce(
          (total, i) => total + i.itens.reduce((acc, item) => acc + item.value, 0),
          0
        ) - cartItem.details.implementations.reduce(
          (total, implementation) => total + implementation.value,
          0
        ) : cartItem.details.value,
        withoutSymbol: true
      })})` : "";
      const flavorsString = cartItem.type === "pizza" && cartItem.details.flavors.length > 1 ? `${cartItem.details.flavors.length} Sabores` : "";
      const specialCharsRegex = new RegExp(/(\W)/, "g");
      const regex = cartItem.type === "pizza" ? new RegExp(
        `${cartItem.details.flavors.length > 1 ? flavorsString.replace(specialCharsRegex, "\\$1") : cartItem.details.flavors[0].name.replace(
          specialCharsRegex,
          "\\$1"
        )}.+`,
        "g"
      ) : "";
      const cartItemName = cartItem.type === "default" ? cartItem.name : cartItem.name?.replace(regex, flavorsString);
      return /* @__PURE__ */ jsxs2("div", { children: [
        /* @__PURE__ */ jsx6(
          Print.Row,
          {
            left: `${cartItem.quantity}X | ${cartItemName}`,
            right: `${cartItemTotalWithOutSymbol}`
          }
        ),
        cartItem.type === "pizza" && /* @__PURE__ */ jsxs2(Fragment2, { children: [
          cartItem.details.implementations?.map(
            (implementatiton) => implementationLayout(implementatiton)
          ),
          cartItem.details.flavors?.map((flavor, index) => /* @__PURE__ */ jsxs2(Fragment, { children: [
            /* @__PURE__ */ jsx6(
              Print.Row,
              {
                leftClass: "complement-space",
                left: `${" ".repeat(3)}${flavor.name}`
              }
            ),
            flavor.complements?.map(
              (complement) => complementLayout(complement)
            ),
            flavor.implementations?.map(
              (implementatiton) => implementationLayout(implementatiton)
            )
          ] }, `${flavor.code}-${index}`))
        ] }),
        (cartItem.type === "default" || !profile.options.pizza.multipleComplements) && cartItem.details.complements?.map(
          (complement) => complementLayout(complement)
        ),
        cartItem.obs.length > 0 && /* @__PURE__ */ jsx6(Print.Row, { className: "observation-space", left: `Obs: ${cartItem.obs}` }),
        /* @__PURE__ */ jsx6(
          Print.Row,
          {
            left: " ",
            center: "",
            right: `${cartItemTotal}`
          },
          `${cartItem.id}-price`
        ),
        /* @__PURE__ */ jsx6(Print.LineSeparator, {})
      ] }, cartItem.id);
    });
  };
  const getTotal = (type) => {
    switch (printType) {
      case "table":
        if (type === "subtotal") {
          return table?.opened?.getTotalValue("table");
        }
        if (type === "total") {
          return table?.opened?.getTotalValue("tableFee");
        }
      case "command":
        if (type === "subtotal") {
          return command?.getTotalValue("command");
        }
        if (type === "total") {
          return command?.getTotalValue("commandFee");
        }
      default:
        return cart.getTotalValue(type);
    }
  };
  const getTransshipment = () => {
    let result = 0;
    switch (printType) {
      case "command":
        if (cart.command) {
          result = cart.command.carts.filter((c) => c.status !== "canceled").reduce((total, cart2) => total += cart2.transshipment(), 0);
        }
        break;
      case "table":
        if (table) {
          result = table.activeCommands().flatMap((command2) => command2.carts).filter((c) => c.status).reduce((total, cart2) => total += cart2.transshipment(), 0);
        }
        break;
      default:
        result = cart.transshipment();
        break;
    }
    return result;
  };
  const tax = () => {
    let verifyNeighborood;
    if (profile.typeDelivery === "neighborhood") {
      let verifyNeighborood2 = profile.taxDelivery.map((tax2) => {
        return tax2?.neighborhoods.filter(
          (n) => n.name === cart?.address?.neighborhood
        );
      });
      if (verifyNeighborood2[0][0]?.value === null) {
        return "\xC0 Consultar";
      } else {
        return `${currency({ value: cart.taxDelivery, withoutSymbol: true })}`;
      }
    }
    if (profile.typeDelivery === "km") {
      if (cart.taxDelivery === null) {
        return "\xC0 Consultar";
      } else {
        return `${currency({ value: cart.taxDelivery, withoutSymbol: true })}`;
      }
    }
  };
  const valueConsult = () => {
    let consult;
  };
  const haveTransshipment = getTransshipment() > 0;
  const sponsorCupomIfood = ({ cart: cart2 }) => {
    if (cart2.ifoodAditionalInfo?.metadata.benefits) {
      return cart2.ifoodAditionalInfo?.metadata.benefits[0].sponsorshipValues.filter((sponsorValue) => sponsorValue.value > 0).map((sponsorName) => {
        switch (sponsorName.description) {
          case "Incentivo da Loja":
            return "LOJA";
          case "Incentivo do iFood":
            return "IFOOD";
          case "Incentivo da Industria":
            return "Industria";
          case "Incentivo da Rede":
            return "Rede";
          default:
            return "";
        }
      });
    }
  };
  const valuesSponsorCupomIfood = ({ cart: cart2 }) => {
    if (cart2.ifoodAditionalInfo?.metadata.benefits) {
      return cart2.ifoodAditionalInfo?.metadata.benefits[0].sponsorshipValues.filter((sponsorValue) => sponsorValue.value > 0).map((sponsorName) => {
        switch (sponsorName.description) {
          case "Incentivo da Loja":
            return sponsorName.value;
          case "Incentivo do iFood":
            return sponsorName.value;
          case "Incentivo da Industria":
            return sponsorName.value;
          case "Incentivo da Rede":
            return sponsorName.value;
          default:
            return 0;
        }
      });
    }
  };
  const commandWithNfce = table?.opened?.commands.flatMap((command2) => command2.carts.filter((cartFilter) => cartFilter.command?.id === cart.command?.id && cartFilter.controls.grovenfe));
  const tableWithNfce = table?.activeCommands().flatMap((command2) => command2.carts.filter((cartFilter) => cartFilter.controls.grovenfe));
  return /* @__PURE__ */ jsxs2(
    Print.Root,
    {
      ref,
      printMode: pdf ? "pdf" : profile.options?.print.textOnly ? "text-only" : "formated",
      appMode: profile.options.print.app,
      paperWidthSize: profile.options.print.width === "302px" ? electron ? 46 : 48 : electron && !profile.options?.print.textOnly ? 23 : 32,
      paperSize,
      fontSize: profile.options.print.fontSize,
      children: [
        /* @__PURE__ */ jsx6(Print.Row, { center: profile.name, className: "print-title" }),
        /* @__PURE__ */ jsx6(Print.Breakline, {}),
        /* @__PURE__ */ jsx6(
          Print.Row,
          {
            left: DateTime.fromSQL(cart.created_at, { zone: "America/Sao_Paulo" }).setZone(profile.timeZone).toFormat("dd/MM/yyyy HH:mm:ss").trim()
          }
        ),
        !printType && /* @__PURE__ */ jsx6(
          Print.Row,
          {
            left: `Pedido: wm${cart.code}-${cart.type} ${cart.status === "canceled" ? " (CANCELADO)" : ""}`
          }
        ),
        cart.type === "T" ? /* @__PURE__ */ jsx6(
          Print.Row,
          {
            left: `Mesa: ${table?.deleted_at ? table?.name.replace(table?.name.substring(table?.name.length - 25), " (Desativada)") : table?.name}`
          }
        ) : null,
        /* @__PURE__ */ jsx6(
          Print.Row,
          {
            left: `${printType === "command" || cart.type === "T" ? "Comanda" : "Cliente"}: ${cart.nameClient()} `
          }
        ),
        cart.type === "T" && !printType && cart.bartender && /* @__PURE__ */ jsx6(
          Print.Row,
          {
            left: `Gar\xE7om: ${cart.bartender.deleted_at ? cart.bartender.name.replace(
              cart.bartender.name.substring(
                cart.bartender.name.length - 19
              ),
              " (Desativado)"
            ) : cart.bartender.name}`
          }
        ),
        cart.type === "P" && /* @__PURE__ */ jsx6(Print.Row, { left: `Data Entrega: ${cart.date().formatted}` }),
        cart.type !== "T" && cart.client !== null && /* @__PURE__ */ jsx6(Print.Row, { left: `Tel: ${cart.returnMaskedContact()}` }),
        cart.origin === "ifood" && /* @__PURE__ */ jsx6(Print.Row, { left: `C\xF3digo localizador: ${cart.client.codeLocalizer}` }),
        cart.secretNumber && /* @__PURE__ */ jsx6(
          Print.Row,
          {
            left: `${cart.secretNumber.length <= 11 ? "CPF" : "CNPJ"}: ${cart.secretNumber}`
          }
        ),
        printType === "table" && /* @__PURE__ */ jsx6(
          Print.Row,
          {
            left: `Perman\xEAncia: ${cart.permenance(false, table?.opened)}`
          }
        ),
        /* @__PURE__ */ jsx6(Print.LineSeparator, {}),
        detailedTable ? table?.opened?.getCarts()?.map((cart2) => {
          return cart2.status !== "canceled" ? /* @__PURE__ */ jsxs2(Fragment2, { children: [
            /* @__PURE__ */ jsx6(Print.Row, { left: `Pedido: wm${cart2.code}-${cart2.type}` }),
            getItemsToPrint(
              cart2.groupItens(profile.options.print.groupItems)
            )
          ] }) : null;
        }) : getItemsToPrint(getItens()),
        (printType === "command" || printType === "table") && /* @__PURE__ */ jsxs2(Fragment2, { children: [
          getFeesToPrint().filter((fee) => fee.automatic && fee.deleted_at === null)?.map((fee) => {
            let value = 0;
            if (fee.type === "fixed") {
              value = fee.value * fee.quantity;
            } else {
              value = fee.value / 100 * (printType === "command" || !printType ? cart.command?.getTotalValue("command") || 0 : table?.opened?.getTotalValue("table") || 0);
            }
            const feeTotal = currency({
              value,
              withoutSymbol: true
            });
            return value ? /* @__PURE__ */ jsx6(
              Print.Row,
              {
                left: `${fee.code}`,
                right: `${feeTotal}`
              },
              fee.code
            ) : null;
          }),
          getFeesToPrint().filter(
            (fee) => fee.automatic && fee.deleted_at === null
          ).length > 0 && /* @__PURE__ */ jsx6(Print.LineSeparator, {})
        ] }),
        printType === "table" && table?.opened?.commands.length && // printType === 'command' && cart.type === 'T'
        /* @__PURE__ */ jsxs2(Fragment2, { children: [
          table?.opened?.commands.filter(
            (command2) => command2.carts.filter((c) => c.status !== "canceled").length
          )?.map((command2) => {
            const commandTotalValue = currency({
              value: command2.getTotalValue("command"),
              withoutSymbol: true
            });
            return /* @__PURE__ */ jsx6(
              Print.Row,
              {
                left: `${command2.name} `,
                center: `${command2.fullPaid() ? " PAGO " : ""}`,
                right: ` ${commandTotalValue}`
              },
              command2.id
            );
          }),
          /* @__PURE__ */ jsx6(Print.LineSeparator, {})
        ] }),
        cart.obs ? /* @__PURE__ */ jsxs2(Fragment2, { children: [
          /* @__PURE__ */ jsx6(Print.Row, { className: "observation-space", left: `Obs: ${cart.obs}` }),
          /* @__PURE__ */ jsx6(Print.LineSeparator, {})
        ] }) : null,
        /* @__PURE__ */ jsxs2(Fragment2, { children: [
          cart.cupom && /* @__PURE__ */ jsx6(
            Print.Row,
            {
              left: `${cart.origin === "ifood" ? "Cupom dado:" : "Cupom usado:"}`,
              right: `${cart.origin === "ifood" ? sponsorCupomIfood({ cart })?.join(", ") : cart.cupom.code}`
            }
          ),
          cart.cupom && cart.origin === "ifood" && /* @__PURE__ */ jsx6(
            Print.Row,
            {
              left: `Valores de cada cupom`,
              right: `${valuesSponsorCupomIfood({ cart })?.join(", ")}`
            }
          ),
          /* @__PURE__ */ jsx6(
            Print.Row,
            {
              left: `Sub-Total:`,
              right: `${currency({ value: getTotal("subtotal"), withoutSymbol: true })}`
            }
          ),
          cart.address && cart.type !== "T" && /* @__PURE__ */ jsx6(
            Print.Row,
            {
              left: `Taxa Entrega:`,
              right: cart.origin === "ifood" ? `${currency({ value: cart.taxDelivery, withoutSymbol: true })}` : tax()
            }
          ),
          cart.origin === "ifood" && cart.taxIfood > 0 && /* @__PURE__ */ jsx6(
            Print.Row,
            {
              left: `Taxa Servi\xE7o Ifood:`,
              right: `${currency({ value: Number(cart?.taxIfood), withoutSymbol: true })}`
            }
          ),
          cart.formsPayment && getFormsPaymentToPrint()[0]?.addon?.status && /* @__PURE__ */ jsx6(
            Print.Row,
            {
              left: `${addonTypeLabel()}:`,
              right: `${currency({ value: cart.getTotalValue("addon"), withoutSymbol: true })}`
            }
          ),
          cart.cupom && /* @__PURE__ */ jsx6(
            Print.Row,
            {
              left: `Cupom:`,
              right: `-${cart.origin === "ifood" ? cart.cupom.value : getCupomValue().cupomDisplayValue}`
            }
          ),
          getFormsPaymentToPrint(false).some(
            (formPayment) => formPayment.payment === "cashback"
          ) && /* @__PURE__ */ jsx6(
            Print.Row,
            {
              left: `Cashback:`,
              right: `-${currency({ value: cart.getTotalValue("cashback"), withoutSymbol: true })}`
            }
          ),
          /* @__PURE__ */ jsx6(
            Print.Row,
            {
              left: `Total:`,
              right: `${currency({ value: getTotal("total"), withoutSymbol: true })}`
            }
          ),
          haveTransshipment && cart.formsPayment.filter(
            (formPayment) => formPayment.payment !== "cashback"
          ).length ? /* @__PURE__ */ jsxs2(Fragment2, { children: [
            /* @__PURE__ */ jsx6(
              Print.Row,
              {
                left: `Troco para:`,
                right: `${currency({ value: getTransshipment(), withoutSymbol: true })}`
              }
            ),
            /* @__PURE__ */ jsx6(
              Print.Row,
              {
                left: `Troco:`,
                right: `${currency({ value: Math.max(getTransshipment() - getTotal("total"), 0), withoutSymbol: true })}`
              }
            )
          ] }) : null,
          getFormsPaymentToPrint()?.length < 2 && !printType ? /* @__PURE__ */ jsx6(Fragment2, { children: getFormsPaymentToPrint()[0] ? /* @__PURE__ */ jsx6(
            Print.Row,
            {
              left: `Pagamento em:`,
              right: `${getFormsPaymentToPrint()[0]?.label}${(typeof getFormsPaymentToPrint()[0]?.flag === "string" ? getFormsPaymentToPrint()[0]?.flag : getFormsPaymentToPrint()[0]?.flag?.name) ? " - " + (typeof getFormsPaymentToPrint()[0]?.flag === "string" ? getFormsPaymentToPrint()[0]?.flag : getFormsPaymentToPrint()[0]?.flag?.name) : ""}`
            }
          ) : null }) : /* @__PURE__ */ jsxs2(Fragment2, { children: [
            /* @__PURE__ */ jsx6(Print.Row, { left: `Total Pago:`, right: `${getPaidValue()}` }),
            /* @__PURE__ */ jsx6(Print.Row, { left: `Formas de pagamento:` }),
            table?.opened?.formsPayment?.map((formPayment) => {
              return /* @__PURE__ */ jsx6(
                Print.Row,
                {
                  left: formPayment.label,
                  right: currency({ value: formPayment.value, withoutSymbol: true })
                },
                formPayment.code
              );
            }),
            getFormsPaymentToPrint()?.map((formPayment, index) => {
              return /* @__PURE__ */ jsxs2(Fragment2, { children: [
                /* @__PURE__ */ jsx6(
                  Print.Row,
                  {
                    leftClass: "transshipment-space",
                    left: `${formPayment.change ? "Troco para" : formPayment.label}${formPayment.flag && formPayment.flag.name ? " - " + formPayment.flag.name : ""}`,
                    right: `${currency({ value: formPayment.change ?? formPayment.value, withoutSymbol: true })}`
                  },
                  `${formPayment.code}-${index}`
                ),
                formPayment.change ? /* @__PURE__ */ jsx6(Fragment2, { children: /* @__PURE__ */ jsx6(
                  Print.Row,
                  {
                    left: `  Troco`,
                    leftClass: "transshipment-space",
                    right: `${currency({ value: formPayment.change - formPayment.value, withoutSymbol: true })}`
                  }
                ) }) : null
              ] });
            }),
            Number(getLackValue()) > 0 && /* @__PURE__ */ jsx6(Print.Row, { left: `Faltam:`, right: `${getLackValue()}` }),
            cart.getTotalValue("paid") - cart.getTotalValue("total") > 0 && /* @__PURE__ */ jsx6(
              Print.Row,
              {
                left: "Fechamento:",
                right: `${currency({ value: cart.getTotalValue("paid") - cart.getTotalValue("total"), withoutSymbol: true })}`
              }
            )
          ] }),
          cart.origin === "ifood" && /* @__PURE__ */ jsx6(Print.Row, { className: "fw-bold", center: "PEDIDO IFOOD" }),
          cart.origin === "ifood" && /* @__PURE__ */ jsx6(
            Print.Row,
            {
              className: "fw-bold",
              left: "C\xF3digo de coleta",
              right: cart.controls.pickupCode
            }
          ),
          cart.statusPayment === "paid" && /* @__PURE__ */ jsx6(Print.Row, { center: "PAGO ONLINE" }),
          /* @__PURE__ */ jsx6(Print.LineSeparator, {})
        ] }),
        cart.address && cart.type !== "T" ? /* @__PURE__ */ jsxs2(Fragment2, { children: [
          /* @__PURE__ */ jsx6(Print.Row, { left: `${cart.address.street},` }),
          /* @__PURE__ */ jsx6(
            Print.Row,
            {
              left: `${cart.address.number || "S/N"} ${cart.address.complement || ""}`
            }
          ),
          /* @__PURE__ */ jsx6(
            Print.Row,
            {
              left: `${cart.address.neighborhood} - ${cart.address.city}`
            }
          ),
          cart.address.reference && /* @__PURE__ */ jsx6(Print.Row, { left: `${cart.address.reference}` }),
          cart && cart.motoboyId && /* @__PURE__ */ jsx6(
            Print.Row,
            {
              left: `Entregador: ${electron ? cart.motoboy?.name : motoboys?.find((motoboy) => motoboy.id === cart.motoboyId)?.name}`
            }
          ),
          /* @__PURE__ */ jsx6(Print.LineSeparator, {})
        ] }) : null,
        /* @__PURE__ */ jsxs2(Fragment2, { children: [
          cart.controls.grovenfe?.fiscal_note?.aditional_info?.qrcode_url && printType !== "command" && printType !== "table" && /* @__PURE__ */ jsxs2(Fragment2, { children: [
            /* @__PURE__ */ jsx6(Print.Breakline, {}),
            /* @__PURE__ */ jsx6(Print.Row, { center: "Consulte pela Chave de Acesso em:" }),
            /* @__PURE__ */ jsx6(
              Print.Row,
              {
                className: "longText",
                left: cart.controls.grovenfe?.fiscal_note?.aditional_info?.url_consulta_nf
              }
            ),
            /* @__PURE__ */ jsx6(Print.Breakline, {}),
            /* @__PURE__ */ jsx6(Print.Row, { center: "Chave de Acesso:" }),
            /* @__PURE__ */ jsx6(
              Print.Row,
              {
                center: cart.controls.grovenfe?.fiscal_note?.aditional_info?.chave_nfe?.replace(
                  "NFe",
                  ""
                )
              }
            ),
            /* @__PURE__ */ jsx6(Print.Breakline, {}),
            /* @__PURE__ */ jsx6(Print.Row, { center: "Protocolo:" }),
            /* @__PURE__ */ jsx6(
              Print.Row,
              {
                center: cart.controls.grovenfe?.fiscal_note?.aditional_info?.protocolo
              }
            ),
            /* @__PURE__ */ jsx6(
              Print.Row,
              {
                center: DateTime.fromISO(
                  cart.controls.grovenfe?.fiscal_note?.created_at,
                  { zone: "America/Sao_Paulo" }
                ).setZone(profile.timeZone).toFormat("dd/MM/yyyy HH:mm:ss").trim()
              }
            ),
            /* @__PURE__ */ jsx6(Print.Breakline, {}),
            !profile.options?.print.textOnly && /* @__PURE__ */ jsxs2(Fragment2, { children: [
              /* @__PURE__ */ jsx6(Print.Row, { center: "Consulta via leitor de QR Code:" }),
              /* @__PURE__ */ jsx6(Print.Breakline, {}),
              /* @__PURE__ */ jsx6(
                Print.Row,
                {
                  className: "d-flex justify-content-center",
                  children_controls: "mid",
                  children: /* @__PURE__ */ jsx6(
                    QRCodeCanvas,
                    {
                      value: cart.controls.grovenfe?.fiscal_note?.aditional_info.qrcode_url,
                      size: 200
                    }
                  )
                }
              ),
              /* @__PURE__ */ jsx6(Print.Breakline, {})
            ] })
          ] }),
          commandWithNfce && commandWithNfce?.length > 0 && printType === "command" && /* @__PURE__ */ jsxs2(Fragment2, { children: [
            /* @__PURE__ */ jsx6(Print.Row, { center: "IMPRESS\xC3O COMANDA" }),
            /* @__PURE__ */ jsx6(Print.Breakline, {}),
            /* @__PURE__ */ jsx6(Print.Row, { center: "Consulte pela Chave de Acesso em:" }),
            /* @__PURE__ */ jsx6(
              Print.Row,
              {
                className: "longText",
                left: commandWithNfce[0]?.controls.grovenfe?.fiscal_note?.aditional_info?.url_consulta_nf
              }
            ),
            /* @__PURE__ */ jsx6(Print.Breakline, {}),
            /* @__PURE__ */ jsx6(Print.Row, { center: "Chave de Acesso:" }),
            /* @__PURE__ */ jsx6(
              Print.Row,
              {
                center: commandWithNfce[0]?.controls.grovenfe?.fiscal_note?.aditional_info?.chave_nfe?.replace(
                  "NFe",
                  ""
                )
              }
            ),
            /* @__PURE__ */ jsx6(Print.Breakline, {}),
            /* @__PURE__ */ jsx6(Print.Row, { center: "Protocolo:" }),
            /* @__PURE__ */ jsx6(
              Print.Row,
              {
                center: commandWithNfce[0]?.controls.grovenfe?.fiscal_note?.aditional_info?.protocolo
              }
            ),
            /* @__PURE__ */ jsx6(
              Print.Row,
              {
                center: DateTime.fromISO(
                  commandWithNfce[0]?.controls.grovenfe?.fiscal_note?.created_at,
                  { zone: "America/Sao_Paulo" }
                ).setZone(profile.timeZone).toFormat("dd/MM/yyyy HH:mm:ss").trim()
              }
            ),
            /* @__PURE__ */ jsx6(Print.Breakline, {})
          ] }),
          tableWithNfce && tableWithNfce?.length > 0 && printType === "table" && /* @__PURE__ */ jsxs2(Fragment2, { children: [
            /* @__PURE__ */ jsx6(Print.Row, { center: "IMPRESS\xC3O MESA" }),
            /* @__PURE__ */ jsx6(Print.Breakline, {}),
            /* @__PURE__ */ jsx6(Print.Row, { center: "Consulte pela Chave de Acesso em:" }),
            /* @__PURE__ */ jsx6(
              Print.Row,
              {
                className: "longText",
                left: tableWithNfce[0]?.controls.grovenfe?.fiscal_note?.aditional_info?.url_consulta_nf
              }
            ),
            /* @__PURE__ */ jsx6(Print.Breakline, {}),
            /* @__PURE__ */ jsx6(Print.Row, { center: "Chave de Acesso:" }),
            /* @__PURE__ */ jsx6(
              Print.Row,
              {
                center: tableWithNfce[0]?.controls.grovenfe?.fiscal_note?.aditional_info?.chave_nfe?.replace(
                  "NFe",
                  ""
                )
              }
            ),
            /* @__PURE__ */ jsx6(Print.Breakline, {}),
            /* @__PURE__ */ jsx6(Print.Row, { center: "Protocolo:" }),
            /* @__PURE__ */ jsx6(
              Print.Row,
              {
                center: tableWithNfce[0]?.controls.grovenfe?.fiscal_note?.aditional_info?.protocolo
              }
            ),
            /* @__PURE__ */ jsx6(
              Print.Row,
              {
                center: DateTime.fromISO(
                  tableWithNfce[0]?.controls.grovenfe?.fiscal_note?.created_at,
                  { zone: "America/Sao_Paulo" }
                ).setZone(profile.timeZone).toFormat("dd/MM/yyyy HH:mm:ss").trim()
              }
            ),
            /* @__PURE__ */ jsx6(Print.Breakline, {})
          ] }),
          /* @__PURE__ */ jsx6(
            Print.Row,
            {
              center: `${cart?.typeDeliveryText(textPackage)}`
            }
          ),
          /* @__PURE__ */ jsx6(Print.Row, { center: "Tecnologia" }),
          /* @__PURE__ */ jsx6(Print.Row, { center: "www.whatsmenu.com.br" })
        ] }),
        profile.options.print.textOnly && !profile.options.print.web && /* @__PURE__ */ jsxs2(Fragment2, { children: [
          /* @__PURE__ */ jsx6(Print.Breakline, {}),
          /* @__PURE__ */ jsx6(Print.Breakline, {}),
          /* @__PURE__ */ jsx6(Print.Breakline, {})
        ] })
      ]
    }
  );
});

// src/components/ProductionPrint/index.tsx
import { DateTime as DateTime2 } from "luxon";
import { Fragment as Fragment3, forwardRef as forwardRef3 } from "react";
import { Fragment as Fragment4, jsx as jsx7, jsxs as jsxs3 } from "react/jsx-runtime";
var ProductionPrint = forwardRef3(function({
  profile,
  cart,
  printType,
  table,
  detailedTable,
  command,
  pdf,
  electron,
  paperSize
}, ref) {
  if (!cart) {
    return null;
  }
  if (!printType) {
    table = cart?.command?.opened?.table;
  }
  const getItens = () => {
    let items = [];
    switch (printType) {
      case "table":
        if (table && table.opened) {
          items = table.opened.commands.flatMap(
            (command2) => command2.carts.filter((c) => c.status !== "canceled").reduce((carts, cart2) => {
              if (!carts.some((c) => c.id === cart2.id)) {
                carts.push(cart2);
              }
              return carts;
            }, []).flatMap((cart2) => {
              return cart2.groupItens(profile.options.print.groupItems);
            })
          );
        }
        break;
      case "command":
        if (command) {
          items = command.carts.filter((c) => c.status !== "canceled").flatMap((cart2) => {
            return cart2.groupItens(profile.options.print.groupItems);
          });
        }
        break;
      default:
        items = cart.groupItens(profile.options.print.groupItems);
        break;
    }
    return items;
  };
  const getFeesToPrint = () => {
    let fees = [];
    switch (printType) {
      case "table":
        fees = table?.opened?.getUpdatedFees(false, true) ?? [];
        break;
      default:
        fees = cart?.command?.fees ?? [];
        break;
    }
    return fees;
  };
  const complementLayout = (complement) => {
    return /* @__PURE__ */ jsxs3(Fragment4, { children: [
      /* @__PURE__ */ jsx7(Print.Breakline, {}),
      /* @__PURE__ */ jsx7(
        Print.Row,
        {
          leftClass: "complement-space",
          left: `${" ".repeat(3)}${complement.name}`
        },
        complement.id
      ),
      complement.itens?.map((complementItem, index) => {
        const complementItemTotal = complementItem.value > 0 ? `(${currency({ value: complementItem.value * (complementItem.quantity || 1), withoutSymbol: true })})` : "";
        return /* @__PURE__ */ jsx7(
          Print.Row,
          {
            leftClass: "item-space",
            left: `${" ".repeat(5)}${complementItem.quantity}X | ${complementItem.name}`,
            center: "",
            right: `${complementItemTotal} `
          },
          `${complementItem.code}-${index}`
        );
      })
    ] });
  };
  const implementationLayout = (implementation) => {
    if (!implementation) {
      return /* @__PURE__ */ jsx7(Fragment4, {});
    }
    return /* @__PURE__ */ jsx7(
      Print.Row,
      {
        leftClass: "complement-space",
        left: `${" ".repeat(3)}${implementation.name}`,
        right: `${currency({ value: implementation.value, withoutSymbol: true })}`
      },
      implementation.code
    );
  };
  const getItemsToPrint = (items) => {
    return items?.map((cartItem) => {
      const cartItemTotal = cartItem.details.value > 0 ? `${currency({ value: cartItem.getTotal(cartItem.type === "default"), withoutSymbol: true })}` : "";
      const cartItemTotalWithOutSymbol = cartItem.details.value > 0 ? `(${currency({
        value: cartItem.type === "pizza" ? cartItem.details.value - cartItem.details.complements.reduce(
          (total, i) => total + i.itens.reduce((acc, item) => acc + item.value, 0),
          0
        ) - cartItem.details.implementations.reduce(
          (total, implementation) => total + implementation.value,
          0
        ) : cartItem.details.value,
        withoutSymbol: true
      })})` : "";
      const flavorsString = cartItem.type === "pizza" && cartItem.details.flavors.length > 1 ? `${cartItem.details.flavors.length} Sabores` : "";
      const specialCharsRegex = new RegExp(/(\W)/, "g");
      const regex = cartItem.type === "pizza" ? new RegExp(
        `${cartItem.details.flavors.length > 1 ? flavorsString.replace(specialCharsRegex, "\\$1") : cartItem.details.flavors[0].name.replace(
          specialCharsRegex,
          "\\$1"
        )}.+`,
        "g"
      ) : "";
      const cartItemName = cartItem.type === "default" ? cartItem.name : cartItem.name?.replace(regex, flavorsString);
      return /* @__PURE__ */ jsxs3("div", { children: [
        /* @__PURE__ */ jsx7(
          Print.Row,
          {
            left: `${cartItem.quantity}X | ${cartItemName}`,
            right: `${cartItemTotalWithOutSymbol}`
          }
        ),
        cartItem.type === "pizza" && /* @__PURE__ */ jsxs3(Fragment4, { children: [
          cartItem.details.implementations?.map(
            (implementatiton) => implementationLayout(implementatiton)
          ),
          cartItem.details.flavors?.map((flavor, index) => /* @__PURE__ */ jsxs3(Fragment3, { children: [
            /* @__PURE__ */ jsx7(
              Print.Row,
              {
                leftClass: "complement-space",
                left: `${" ".repeat(3)}${flavor.name}`
              }
            ),
            flavor.complements?.map(
              (complement) => complementLayout(complement)
            ),
            flavor.implementations?.map(
              (implementatiton) => implementationLayout(implementatiton)
            )
          ] }, `${flavor.code}-${index}`))
        ] }),
        (cartItem.type === "default" || !profile.options.pizza.multipleComplements) && cartItem.details.complements?.map(
          (complement) => complementLayout(complement)
        ),
        cartItem.obs.length > 0 && /* @__PURE__ */ jsx7(Print.Row, { className: "observation-space", left: `Obs: ${cartItem.obs}` }),
        /* @__PURE__ */ jsx7(
          Print.Row,
          {
            left: " ",
            center: "",
            right: `${cartItemTotal}`
          },
          `${cartItem.id}-price`
        ),
        /* @__PURE__ */ jsx7(Print.LineSeparator, {})
      ] }, cartItem.id);
    });
  };
  return /* @__PURE__ */ jsxs3(
    Print.Root,
    {
      ref,
      printMode: pdf ? "pdf" : profile.options?.print.textOnly ? "text-only" : "formated",
      appMode: profile.options.print.app,
      paperWidthSize: profile.options.print.width === "302px" ? electron ? 46 : 48 : electron && !profile.options?.print.textOnly ? 23 : 32,
      paperSize,
      fontSize: profile.options.print.fontSize,
      children: [
        /* @__PURE__ */ jsx7(Print.Row, { center: profile.name, className: "print-title" }),
        /* @__PURE__ */ jsx7(Print.Breakline, {}),
        /* @__PURE__ */ jsx7(
          Print.Row,
          {
            left: DateTime2.fromSQL(cart.created_at, { zone: "America/Sao_Paulo" }).setZone(profile.timeZone).toFormat("dd/MM/yyyy HH:mm:ss").trim()
          }
        ),
        !printType && /* @__PURE__ */ jsx7(
          Print.Row,
          {
            left: `Pedido: wm${cart.code}-${cart.type} ${cart.status === "canceled" ? " (CANCELADO)" : ""}`
          }
        ),
        cart.type === "T" ? /* @__PURE__ */ jsx7(
          Print.Row,
          {
            left: `Mesa: ${table?.deleted_at ? table?.name.replace(table?.name.substring(table?.name.length - 25), " (Desativada)") : table?.name}`
          }
        ) : null,
        /* @__PURE__ */ jsx7(
          Print.Row,
          {
            left: `${printType === "command" || cart.type === "T" ? "Comanda" : "Cliente"}: ${cart.nameClient()} `
          }
        ),
        cart.type === "T" && !printType && cart.bartender && /* @__PURE__ */ jsx7(
          Print.Row,
          {
            left: `Gar\xE7om: ${cart.bartender.deleted_at ? cart.bartender.name.replace(
              cart.bartender.name.substring(
                cart.bartender.name.length - 19
              ),
              " (Desativado)"
            ) : cart.bartender.name}`
          }
        ),
        cart.type === "P" && /* @__PURE__ */ jsx7(Print.Row, { left: `Data Entrega: ${cart.date().formatted}` }),
        printType === "table" && /* @__PURE__ */ jsx7(
          Print.Row,
          {
            left: `Perman\xEAncia: ${cart.permenance(false, table?.opened)}`
          }
        ),
        /* @__PURE__ */ jsx7(Print.LineSeparator, {}),
        detailedTable ? table?.opened?.getCarts()?.map((cart2) => {
          return cart2.status !== "canceled" ? /* @__PURE__ */ jsxs3(Fragment4, { children: [
            /* @__PURE__ */ jsx7(Print.Row, { left: `Pedido: wm${cart2.code}-${cart2.type}` }),
            getItemsToPrint(
              cart2.groupItens(profile.options.print.groupItems)
            )
          ] }) : null;
        }) : getItemsToPrint(getItens()),
        (printType === "command" || printType === "table") && /* @__PURE__ */ jsxs3(Fragment4, { children: [
          getFeesToPrint().filter((fee) => fee.automatic && fee.deleted_at === null)?.map((fee) => {
            let value = 0;
            if (fee.type === "fixed") {
              value = fee.value * fee.quantity;
            } else {
              value = fee.value / 100 * (printType === "command" || !printType ? cart.command?.getTotalValue("command") || 0 : table?.opened?.getTotalValue("table") || 0);
            }
            const feeTotal = currency({
              value,
              withoutSymbol: true
            });
            return value ? /* @__PURE__ */ jsx7(
              Print.Row,
              {
                left: `${fee.code}`,
                right: `${feeTotal}`
              },
              fee.code
            ) : null;
          }),
          getFeesToPrint().filter(
            (fee) => fee.automatic && fee.deleted_at === null
          ).length > 0 && /* @__PURE__ */ jsx7(Print.LineSeparator, {})
        ] }),
        printType === "table" && table?.opened?.commands.length && // printType === 'command' && cart.type === 'T'
        /* @__PURE__ */ jsxs3(Fragment4, { children: [
          table?.opened?.commands.filter(
            (command2) => command2.carts.filter((c) => c.status !== "canceled").length
          )?.map((command2) => {
            const commandTotalValue = currency({
              value: command2.getTotalValue("command"),
              withoutSymbol: true
            });
            return /* @__PURE__ */ jsx7(
              Print.Row,
              {
                left: `${command2.name} `,
                center: `${command2.fullPaid() ? " PAGO " : ""}`,
                right: ` ${commandTotalValue}`
              },
              command2.id
            );
          }),
          /* @__PURE__ */ jsx7(Print.LineSeparator, {})
        ] }),
        cart.obs ? /* @__PURE__ */ jsxs3(Fragment4, { children: [
          /* @__PURE__ */ jsx7(Print.Row, { className: "observation-space", left: `Obs: ${cart.obs}` }),
          /* @__PURE__ */ jsx7(Print.LineSeparator, {})
        ] }) : null,
        profile.options.print.textOnly && !profile.options.print.web && /* @__PURE__ */ jsxs3(Fragment4, { children: [
          /* @__PURE__ */ jsx7(Print.Breakline, {}),
          /* @__PURE__ */ jsx7(Print.Breakline, {}),
          /* @__PURE__ */ jsx7(Print.Breakline, {})
        ] })
      ]
    }
  );
});

// src/utils/print-to-string.ts
import { createElement } from "react";
import { renderToString } from "react-dom/server";
var printToString = (Component, {
  cart,
  profile,
  table,
  printType,
  command,
  html = false,
  electron = false
}) => {
  if (!electron) {
    profile.options.print.textOnly = true;
  }
  const props = {
    // cart: new Cart(cart),
    cart,
    profile,
    table,
    // table: table ? new Table(table) : table,
    command,
    // command: command ? new Command(command) : command,
    printType,
    electron
  };
  const layout58 = renderToString(createElement(Component, { ...props, paperSize: 58 }));
  const layout80 = renderToString(createElement(Component, { ...props, paperSize: 80 }));
  const reactComponentString = {
    58: html ? layout58 : layout58.replace(/<[^>]+>/g, "").replaceAll("\xA0", " ").match(/.{1,32}/gmu)?.join("\n") || "",
    80: html ? layout80 : layout80.replace(/<[^>]+>/g, "").replaceAll("\xA0", " ").match(/.{1,48}/gmu)?.join("\n") || "",
    requestId: cart.id
  };
  return reactComponentString;
};
export {
  NotePrint,
  Print,
  PrintBreakline,
  PrintContext,
  PrintLineSeparator,
  PrintProvider,
  PrintRoot,
  PrintRow,
  ProductionPrint,
  printToString
};
